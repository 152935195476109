import { useEffect, useState } from 'react';

export const BREAKPOINTS = {
  SM: 'SM',
  MD: 'MD',
  LG: 'LG',
  XL: 'XL',
};

export const breakpointValues = {
  [BREAKPOINTS.SM]: 576,
  [BREAKPOINTS.MD]: 700,
  [BREAKPOINTS.LG]: 900,
  [BREAKPOINTS.XL]: 1200,
};

function useWindowSize() {
  const [deviceScreenType, setDeviceScreenType] = useState(null);

  const handleResize = () => {
    if (window.innerWidth > breakpointValues[BREAKPOINTS.XL]) {
      setDeviceScreenType(BREAKPOINTS.XL);
    } else if (window.innerWidth > breakpointValues[BREAKPOINTS.LG]) {
      setDeviceScreenType(BREAKPOINTS.LG);
    } else if (window.innerWidth > breakpointValues[BREAKPOINTS.MD]) {
      setDeviceScreenType(BREAKPOINTS.MD);
    } else {
      setDeviceScreenType(BREAKPOINTS.SM);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    deviceScreenType,
  };
}

export default useWindowSize;
