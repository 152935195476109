import { useEffect, useState } from 'react';
import { TabSelector } from '@/components/standalone';
import { Highlight } from 'prism-react-renderer';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './CodePreview.module.scss';

const theme = {
  plain: {
    color: '#3e3d46',
    backgroundColor: '#edecee',
  },
  styles: [
    {
      types: ['punctuation', 'selector'],
      style: {
        color: '#751dff',
      },
    },
    {
      types: ['prolog', 'comment', 'cdata'],
      style: {
        color: 'red',
      },
    },
    {
      types: [
        'property',
        'tag',
        'boolean',
        'number',
        'constant',
        'symbol',
        'deleted',
      ],
      style: {
        color: '#3e3d46',
      },
    },
    {
      types: ['selector', 'attr-name', 'string', 'char', 'builtin', 'inserted'],
      style: {
        color: '#751dff',
      },
    },
    {
      types: [
        'operator',
        'entity',
        'url',
        'string',
        'variable',
        'language-css',
      ],
      style: {
        color: '#751dff',
      },
    },
  ],
};

const propTypes = {
  codeBlock: PropTypes.string.isRequired,
  children: PropTypes.node,
};

function CodePreview({ codeBlock, children }) {
  const onlyCode = !children;
  const [selectedReportTab, setSelectedReportTab] = useState(onlyCode ? 0 : 1);
  const [shouldRenderResult, setShouldRenderResult] = useState(false);

  useEffect(() => {
    if (window && !onlyCode) {
      setShouldRenderResult(true);
    }
  }, [onlyCode]);

  return (
    <div
      className={classNames(styles.codePreview, {
        [styles.codePreviewCodeNotVisible]: selectedReportTab !== 0,
        [styles.codePreviewResultNotVisible]: selectedReportTab !== 1,
      })}
    >
      {!onlyCode && (
        <TabSelector
          selectedTab={selectedReportTab}
          onSelectTab={setSelectedReportTab}
          fill
          data-testid="publish-audit-form-audit-report-type"
          options={[
            {
              text: 'Code',
              icon: 'Code',
            },
            {
              text: 'Result',
              icon: 'Sparkles',
            },
          ]}
        />
      )}
      <Highlight theme={theme} code={codeBlock} language="html">
        {({ style, tokens, getLineProps, getTokenProps }) => (
          <pre style={style} className={styles.codePreviewCode}>
            {tokens
              .slice(
                tokens[0].length === 1 && tokens[0][0].empty ? 1 : 0,
                tokens[tokens.length - 1].length === 1 &&
                  tokens[tokens.length - 1][0].empty
                  ? -1
                  : undefined,
              )
              .map((line, i) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  {...getLineProps({ line })}
                  className={styles.codePreviewCodeLine}
                >
                  <span className={styles.codePreviewCodeLineNumber}>
                    {i + 1}
                  </span>
                  <div>
                    {line.map((token, key) => (
                      <span
                        className={styles.codePreviewCodeLineText}
                        // eslint-disable-next-line react/no-array-index-key
                        key={key}
                        {...getTokenProps({ token })}
                      />
                    ))}
                  </div>
                </div>
              ))}
          </pre>
        )}
      </Highlight>
      {shouldRenderResult && children}
    </div>
  );
}

CodePreview.propTypes = propTypes;
export default CodePreview;
