import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'next/link';
import styles from './Button.module.scss';
import options from '../Icon/options.json';
import Icon from '../Icon/Icon';

const propTypes = {
  icon: PropTypes.oneOf(options),
  mode: PropTypes.oneOf(['primary', 'secondary', 'outline']),
  fill: PropTypes.bool,
  children: PropTypes.node,
  type: PropTypes.oneOf(['button', 'submit', 'link']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  href: PropTypes.string,
  onlyIcon: PropTypes.bool,
  active: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  mode: 'secondary',
  onClick: undefined,
  type: 'button',
  fill: false,
  disabled: false,
  href: '',
};

function Button({
  mode,
  fill,
  children,
  type,
  disabled,
  onClick,
  icon,
  href,
  onlyIcon,
  active,
  className,
}) {
  const modeclassName =
    styles[`tbButton${mode.charAt(0).toUpperCase() + mode.slice(1)}`];

  const finalclassName = classNames(modeclassName, styles.tbButton, className, {
    [styles.tbButtonFill]: fill,
    [styles.tbButtonDisabled]: disabled,
    [styles.tbButtonOnlyIcon]: onlyIcon,
    [styles.tbButtonActive]: active,
    [styles.tbButtonNoIcon]: !icon,
  });

  const defaultContent = icon ? <Icon name={icon} /> : null;

  if (type === 'submit') {
    return (
      <button
        className={finalclassName}
        disabled={disabled}
        type="submit"
        onClick={onClick}
      >
        <div>
          {defaultContent}
          {children}
        </div>
      </button>
    );
  }

  if (type === 'button') {
    return (
      <button
        className={finalclassName}
        disabled={disabled}
        type="button"
        onClick={onClick}
      >
        <div>
          {defaultContent}
          {children}
        </div>
      </button>
    );
  }

  return (
    <Link className={finalclassName} disabled={disabled} href={href}>
      <div>
        {defaultContent}
        {children}
      </div>
    </Link>
  );
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;
export default Button;
