import { useCallback, useEffect, useState } from 'react';

function useOnScreen({
  ref,
  threshold = '0',
  rootMargin = '0px',
  isOnce = false,
  isDisabled = false,
  callback = () => {},
  isAutomatic = true,
}) {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(isDisabled);
  useEffect(() => {
    if ((isIntersecting && isOnce) || isDisabled || !isAutomatic) {
      return () => {};
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
        callback(entry.isIntersecting);
      },
      {
        rootMargin,
        threshold,
      },
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref?.current) observer?.unobserve(ref.current);
    };
  }, [threshold, isIntersecting, isDisabled, ref?.current]); // Empty array ensures that effect is only run on mount and unmount

  const observe = useCallback(
    ({
      ref: manualRef,
      rootMargin: manualRootMargin,
      threshold: manualThreshold,
      callback: manualCallback,
    }) => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Update our state when observer callback fires
          manualCallback(entry.isIntersecting);
        },
        {
          rootMargin: manualRootMargin,
          threshold: manualThreshold,
        },
      );
      if (manualRef.current) {
        observer.observe(manualRef.current);
        return observer;
      }
      return null;
    },
    [],
  );

  const unobserve = useCallback((manualRef, observer) => {
    if (manualRef?.current) observer?.unobserve(manualRef.current);
  }, []);

  return {
    isOnScreen: isIntersecting,
    observe,
    unobserve,
  };
}

export default useOnScreen;
