import { Button, Icon } from '@/components/standalone';
import Image from 'next/image';
import { useRef } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import Link from 'next/link';
import footer from 'public/images/footer.jpg';
import styles from './Footer.module.scss';

const routeSpecifics = {
  '/': {
    headline: (
      <>
        Let’s make trust
        <br />
        and web3 compatible
      </>
    ),
    cta: (
      <Button type="link" href="mailto:contact@trustblock.run" icon="Mail">
        Get in touch
      </Button>
    ),
    cta2: (
      <Button
        type="link"
        mode="outline"
        href="https://app.trustblock.run"
        icon="ArrowRight"
      >
        Go to app
      </Button>
    ),
  },
  '/auditors': {
    headline: (
      <>
        Build the future
        <br />
        of web3 with us
      </>
    ),
    cta: (
      <Button
        type="link"
        href="https://app.trustblock.run/sign-up?type=auditor"
        icon="LogIn"
      >
        Join now
      </Button>
    ),
    cta2: (
      <Button type="link" mode="outline" href="https://app.trustblock.run">
        Go to app
      </Button>
    ),
  },
  '/integrators': {
    headline: (
      <>
        Build the future
        <br />
        of web3 with us
      </>
    ),
    cta: (
      <Button
        type="link"
        href="https://app.trustblock.run/sign-up?type=integrator"
        icon="LogIn"
      >
        Use our API for free
      </Button>
    ),
    cta2: (
      <Button type="link" mode="outline" href="https://app.trustblock.run">
        Go to app
      </Button>
    ),
  },
};

function Footer() {
  const footerRef = useRef();
  const router = useRouter();

  return (
    <footer className={styles.footer} ref={footerRef}>
      <div className={classNames('section', styles.footerTop)}>
        <Image
          fill
          src={footer}
          alt="footer"
          quality={100}
          className={styles.footerTopCover}
        />
        <div className={styles.footerTopContent}>
          <h2 className={styles.footerTopContentHeadline}>
            {(routeSpecifics[router.pathname] || routeSpecifics['/']).headline}
          </h2>
          <div className={styles.footerTopContentCta}>
            {(routeSpecifics[router.pathname] || routeSpecifics['/']).cta}
          </div>
        </div>
      </div>
      <div className={classNames(styles.footerBottom)}>
        <div
          className={classNames(
            'max-content-width',
            styles.footerBottomContent,
          )}
        >
          <div>
            <Icon name="TrustblockLogoFull" />
            <p className={styles.footerDescription}>
              We make web3 a secured-by-default,
              <br />
              user-friendly, composable ecosystem.
            </p>
            <p>All rights reserved · Trustblock</p>
          </div>
          <div>
            <div>
              <h4>About</h4>
              <Link href="https://docs.trustblock.run">Documentation</Link>
              <Link href="/blog">Blog</Link>
              <Link href="https://www.notion.so/trustblock/Trustblock-hires-2474e7c8936f40028ac16c4e080cb138">
                Careers
              </Link>
              <Link href="https://trustblock.notion.site/Brand-assets-547b207deecd4239adfd20c10d605c09">
                Brand Assets
              </Link>
            </div>
            <div>
              <h4>Let&apos;s connect</h4>
              <Link href="https://x.com/TrustblockHQ">X</Link>
              <Link href="https://discord.com/invite/NbdkHYXbTx">Discord</Link>
              <Link href="https://www.linkedin.com/company/trustblockhq">
                LinkedIn
              </Link>
              <Link href="https://www.youtube.com/@TrustblockHQ">Youtube</Link>
            </div>
            <div>
              <h4>Backers</h4>
              <Link href="https://fabric.vc" target="_blank">
                Fabric
              </Link>
              <Link href="https://frst.vc" target="_blank">
                Frst
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
