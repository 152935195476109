import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web/build/player/lottie_light';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import options from './options.json';
import { getAnimation } from './animations/_index';

const propTypes = {
  name: PropTypes.oneOf(options).isRequired,
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  className: PropTypes.string,
  onLoaded: PropTypes.func,
  onComplete: PropTypes.func,
};

const defaultProps = {
  loop: false,
  autoplay: false,
  onLoaded: () => {},
  onComplete: () => {},
};

function SvgAnimation({
  name,
  loop,
  autoplay,
  className,
  onLoaded,
  onComplete,
  ...props
}) {
  const containerRef = useRef(null);
  const animRef = useRef(null);

  useEffect(() => {
    animRef.current = lottie.loadAnimation({
      container: containerRef.current,
      renderer: 'svg',
      loop,
      autoplay,
      animationData: getAnimation(name),
    });

    onLoaded(animRef.current);

    animRef.current.addEventListener('complete', () =>
      onComplete(animRef.current),
    );

    return () => {
      animRef.current.removeEventListener('complete', () =>
        onComplete(animRef.current),
      );
      animRef.current.destroy();
    };
  }, [autoplay]);

  return (
    <div
      className={classNames('animation', name.toLowerCase(), className)}
      ref={containerRef}
      name={className}
      {...props}
    />
  );
}

SvgAnimation.propTypes = propTypes;
SvgAnimation.defaultProps = defaultProps;
export default SvgAnimation;
