import Auditors from './Auditors.json';
import Composability from './Composability.json';
import CriticalStateMonitoring from './CriticalStateMonitoring.json';
import Labels from './Labels.json';
import Labels2 from './Labels2.json';
import Metrics from './Metrics.json';
import Platforms from './Platforms.json';
import Projects from './Projects.json';
import Publish from './Publish.json';

const ANIMATIONS = {
  Auditors,
  Composability,
  CriticalStateMonitoring,
  Labels,
  Labels2,
  Metrics,
  Platforms,
  Projects,
  Publish,
};

export const getAnimation = (name) => ANIMATIONS[name];
