import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../Button/Button';
import styles from './TabSelector.module.scss';
import iconOptions from '../Icon/options.json';

const propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.oneOf(iconOptions),
    }),
  ),
  selectedTab: PropTypes.number,
  onSelectTab: PropTypes.func.isRequired,
  fill: PropTypes.bool,
};

function TabSelector({
  options,
  selectedTab,
  onSelectTab,
  fill = false,
  ...props
}) {
  return (
    <div
      {...props}
      className={classNames(styles.tbTabSelector, {
        [styles.tbTabSelectorFill]: fill,
      })}
    >
      {options.map((option, index) => (
        <Button
          key={option.text}
          fill
          onClick={() => onSelectTab(index)}
          icon={option.icon}
          className={classNames(styles.tbTab, {
            [styles.tbTabSelected]: index === selectedTab,
          })}
        >
          {option.text}
        </Button>
      ))}
    </div>
  );
}

TabSelector.propTypes = propTypes;

export default TabSelector;
